<template>
  <v-container fluid v-if="possuiProcessoSelecionado">
    <v-card rounded width="100%">
      <div :key="processoSelecionado.nome">
        <v-card-title class="justify-center mb-3 titulo text-break">
          <h2>
            {{ processoSelecionado.nome }}
          </h2>
        </v-card-title>
        <v-card-text>
          <v-item-group v-model="model" class="mb-6" mandatory>
            <v-container>
              <v-row dense justify="center">
                <v-item
                  v-for="item in items"
                  :key="item.nome"
                  v-slot="{ active, toggle }"
                  class="mx-2 mt-3"
                >
                  <v-btn
                    :color="active ? 'accent' : 'primary'"
                    :outlined="!active"
                    elevation="2"
                    @click="toggle"
                  >
                    {{ item.nome }}
                  </v-btn>
                </v-item>
              </v-row>
            </v-container>
          </v-item-group>
          <v-scroll-y-transition>
            <div v-if="model != null">
              <v-window v-model="model" touchless>
                <v-window-item v-for="item in items" :key="'item-' + item.nome">
                  <v-container fluid>
                    <component :is="item.componente" />
                  </v-container>
                </v-window-item>
              </v-window>
            </div>
          </v-scroll-y-transition>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "ProcessoSelecionado",

  data() {
    return {
      model: null,
      items: [],
      itemsPadrao: [
        {
          nome: "Editar",
          componente: () => import("@/components/pages/admin/processos/Editar")
        },
        {
          nome: "Calendários",
          componente: () =>
            import("@/components/pages/admin/processos/Calendario")
        },
        {
          nome: "Opções",
          componente: () => import("@/components/pages/admin/processos/Opcoes")
        },
        {
          nome: "Ações",
          componente: () =>
            import("@/components/pages/admin/processos/acoes/Acoes")
        }
      ],
      itemLocalProva: {
        nome: "Local de Prova",
        componente: () => import("@/components/pages/admin/processos/LocalProva")
      }
    };
  },

  async created() {
    await this.atualizarProcessoSelecionado(this.$route.params.oid).catch(
      () => {
        this.$router.push({ name: "404" }).catch(() => {});
      }
    );
  },

  beforeUpdate() {
    if(this.processoSelecionado.possuiAlocacao) {
      this.items = new Set ([
          ...this.itemsPadrao,
          this.itemLocalProva
      ])
    } else {
      this.items = new Set ([ ...this.itemsPadrao] )
    }
  },

  computed: {
    ...mapState("processoSelecionado", ["processoSelecionado"]),
    ...mapGetters("processoSelecionado", ["possuiProcessoSelecionado"])

  },

  methods: {
    ...mapActions("processoSelecionado", ["atualizarProcessoSelecionado"])
  }
};
</script>

<style scoped></style>
